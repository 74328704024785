import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import SignedPersonFull from 'src/components/mobile/signedPersonFull/signedPersonFull.jsx';
import { BlockContainer } from '../../_styles';

const Slide = ({ query }) => (
  <BlockContainer height="2900px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo">
      <SignedPersonFull
        photo={query.zagorskaImage.childImageSharp.fixed}
        text={query.zagorska.body}
        colorText="#fff"
        alt="slide-photo"
        containerStyle={{ height: 'auto', alignItems: 'center', marginTop: '1rem' }}
      />
      <SignedPersonFull
        photo={query.wittekImage.childImageSharp.fixed}
        text={query.wittek.body}
        colorText="#fff"
        alt="slide-photo"
        containerStyle={{ height: 'auto', alignItems: 'center', marginTop: '1rem' }}
      />
      <SignedPersonFull
        photo={query.gertzImage.childImageSharp.fixed}
        text={query.gertz.body}
        colorText="#fff"
        alt="slide-photo"
        containerStyle={{ height: 'auto', alignItems: 'center', marginTop: '1rem' }}
      />
      <SignedPersonFull
        photo={query.pelczynskaImage.childImageSharp.fixed}
        text={query.pelczynska.body}
        colorText="#fff"
        alt="slide-photo"
        containerStyle={{ height: 'auto', alignItems: 'center', marginTop: '1rem' }}
      />
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;

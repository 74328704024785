import React from 'react';
import { useInView } from 'react-intersection-observer';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Hyperlink from 'src/components/_shared/hyperlink/hyperlink';
import {
  ColumnContent, Wrapper, ProfileImage, ProfileContainer,
} from './styles';

const Profile = ({
  body, image, dark, style, column,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.8,
  });
  return (
    <ProfileContainer column={column}>
      <ProfileImage fluid={image} column={column} />
      <ColumnContent dark={dark} ref={ref} visible={inView} style={style} column={column}>
        <MDXProvider components={{
          Wrapper,
          Hyperlink,
          p: (props) => <p {...props} tabIndex="0" />,
        }}
        >
          <MDXRenderer visible={inView}>{body}</MDXRenderer>
        </MDXProvider>
      </ColumnContent>
    </ProfileContainer>
  );
};

export default Profile;

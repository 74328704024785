import styled, { css, keyframes } from 'styled-components';
import Img from 'gatsby-image';
import { fonts, colors } from '../../../../../globals';

const slideUp = keyframes`
  from {
    opacity: 0;
    top: 70px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
`;

const animationTop = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};
const animationBottom = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 400ms forwards
    `;
  }
  return null;
};
const animationTopSecondary = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 1.2s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};

export const ColumnContent = styled.div`
  height: 100%;
  margin-right: 3vw;
  display: flex;
  width: 11vw;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: ${(props) => (props.column ? '' : '8vh')};
  /* &:first-child {
      margin-left: 10vw;
  } */


  & > h2 {
    ${{ ...fonts.headline_1 }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    position: relative;
    opacity: 0;
    top: 60px;
    animation: ${animationTop};
  }

  & > h3 {
    ${{ ...fonts.headline_3a }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    position: relative;
    opacity: 0;
    margin-bottom: 2.43rem;
    animation: ${animationTopSecondary};
    width: 140%;
    font-size: 2.5rem;
    line-height: 1.2;
  }

  & > p, & > div > p {
    ${{ ...fonts.paragraf }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    max-width: 398px;
    display: inline;
  }
  span {
    font-size: 1rem;
    line-height: 1.62rem;
    letter-spacing: 0.03rem;
  }
`;

export const ProfileImage = styled(Img)`
  margin-right: ${(props) => (props.column ? '' : '1rem')};
  width: ${(props) => (props.column ? '100%' : '12rem')};
  height: ${(props) => (props.column ? '' : '12rem')};
  overflow: ${(props) => (props.column ? 'visible !important' : '')};
  @media (max-width: 1500px) {
    width: ${(props) => (props.column ? '100%' : '9rem')};
    height: ${(props) => (props.column ? '' : '9rem')};
  }
`;

export const Wrapper = styled.div`
width: 140%;
max-zoom: 40%;
position: relative;
opacity: 0;
animation: ${animationBottom};
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  margin-top: 17vh;
  margin-left: 2rem;
  margin-right: ${(props) => (props.column ? '2vw' : '')};
  &:first-child {
      margin-left: ${(props) => (props.column ? '20vw' : '10vw')};
  }
`;

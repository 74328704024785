import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import Profile from 'src/components/desktop/profile/profile';
import { SlideXLarge } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_9/4-9-2-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  zagorska: file(relativePath: {eq: "chapter_4/sub_9/4-9-2-zagorska.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  wittek: file(relativePath: {eq: "chapter_4/sub_9/4-9-2-wittek.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  gertz: file(relativePath: {eq: "chapter_4/sub_9/4-9-2-gertz.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  pelczynska: file(relativePath: {eq: "chapter_4/sub_9/4-9-2-pelczynska.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
  }
`);
  return (
    <SlideXLarge style={{ padding: 0 }}>
      <ParallaxMordo column>
        <Mordo
          mainscene
          move={{ x: 0, y: 0 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />

        <Mordo
          scene={1}
          flexi
          move={{ x: -20, y: -10 }}
        >
          <Profile image={assetQuery.zagorska.childImageSharp.fluid} body={query.zagorska.body} column />
          <Profile image={assetQuery.wittek.childImageSharp.fluid} body={query.wittek.body} column />
          <Profile image={assetQuery.gertz.childImageSharp.fluid} body={query.gertz.body} column />
          <Profile image={assetQuery.pelczynska.childImageSharp.fluid} body={query.pelczynska.body} column />

        </Mordo>
      </ParallaxMordo>
    </SlideXLarge>
  );
};

export default Slide;

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_9/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "slide-4-9-1-mobile"}}) {
      body
    }
    file(relativePath: { eq: "chapter_4/sub_9/mobile/4-9-1-bg.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    audioImageA: file(relativePath: { eq: "chapter_4/sub_9/4-9-1-audio.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    },
  }
  `);
  return (
    <Template query={query} title="Część IX" />
  );
};


export default Slide;

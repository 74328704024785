import React from 'react';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';
import { useStaticQuery, graphql } from 'gatsby';
import Video from 'videos/chapter_4/4-9-1-bg.webm';
import VideoMP4 from 'videos/chapter_4/4-9-1-bg.mp4';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils';
import { SlideLarge } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    audioImage: file(relativePath: { eq: "chapter_4/sub_9/4-9-1-audio.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  }
  `);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ color: 'rgba(19,35,46, 0.25)' }}
        >
          <BackgroundAnimation src={Video} mp4={VideoMP4} responsive />
        </Mordo>
        <Mordo
          scene={3}
          move={{ x: -20, y: -10 }}
        >
          <div css={{
            width: '34vw', height: '100%', marginTop: '69%', marginLeft: '14vw',
          }}
          >

            <AudioPlayer audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/491pelczynska.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/491pelczynska.mp4`]} audioDescription={query.audio.excerpt} size={1} audioImage={assetQuery.audioImage.childImageSharp.fluid} />
          </div>

        </Mordo>
        <Mordo
          scene={3}
          move={{ x: 10, y: 20 }}
        >
          <LeftColContent body={query.mdx.body} />
        </Mordo>

      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
